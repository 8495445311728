
import lottie from 'vue-lottie/src/lottie.vue'
import * as animationData from '@/assets/animations/blob-animation.json'

export default {
  components: { lottie },

  props: {
    label: { type: String, default: null },
    href: { type: String, default: null },
    theme: { type: String, default: 'default' },
    loading: { type: Boolean, default: false },
  },

  data() {
    return {
      speed: 1,
    }
  },

  computed: {
    classTheme() {
      return `blobby--theme-${this.theme}`
    },
    processedHref() {
      if (this.href && (this.href.startsWith('/') || this.href.startsWith('https://www.mybeers.fr'))) {
        const slash = this.href.endsWith('/') ? '' : '/';
        return `${this.href}${slash}`;
      }
      return this.href;
    },
  },

  created() {
    this.init()
  },

  beforeDestroy() {
    if (this.anim) {
      try {
        this.anim.destroy()
      } catch (err) {
        // eslint-disable-next-line
        console.warn('Error destroy blob')
      }
    }
  },

  methods: {
    init() {
      this.lottieOptions = {
        animationData: animationData.default,
        renderer: 'canvas',
        speed: this.speed,
        autoplay: !process.env.BLOB_DISABLED,
        rendererSettings: { preserveAspectRatio: 'none' },
      }
    },

    handleAnimation(animation) {
      this.anim = animation
    },

    onOver() {
      if (this.anim) {
        this.anim.setSpeed(1.75)

        if (process.env.BLOB_DISABLED) this.anim.play()
      }
    },

    onOut() {
      if (this.anim) {
        this.anim.setSpeed(1)

        if (process.env.BLOB_DISABLED) this.anim.stop()
      }
    },
  },
}
