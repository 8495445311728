import { removeEncapsulatingTag } from '../utils'
import { parseDataPageDefault } from './dataPageDefault'
import { parseDataVideo, parseDataImage, parseDataGallery } from './dataMedia'

export function parseDataPageFood(data) {
  const commonData = parseDataPageDefault(data)

  const {
    contentTitre: titleData = null,
    contentTexte: textData = null,
    galerie: galleryData = null,
    produits: productsData = null,
    video: videoData = null,
  } = data.content || {}

  // get head video
  const video = videoData ? parseDataVideo(videoData) : null

  // get edito
  const { richTitle: title = null } = titleData || {}
  const { richtext: text = null } = textData || {}
  const edito = { title: removeEncapsulatingTag(title), text }

  // get products
  const products = productsData ? parseFoodPoducts(productsData) : null

  // get gallery
  const gallery = galleryData ? parseDataGallery(galleryData) : null

  return { ...commonData, edito, gallery, products, video }
}

function parseFoodPoducts(data) {
  return data.map(catData => parseFoodPoductCategory(catData))
}

function parseFoodPoductCategory(data) {
  const {
    id,
    produits: itemsData,
    sousTitre: subTitle,
    icone: iconData,
    titre: titleRaw,
    titreBestOf: pushTitle,
    ombreDefault: backgroundData,
  } = data

  const items = itemsData ? itemsData.map(itemData => parseFoodPoductCategoryItem(itemData)) : null
  const title = removeEncapsulatingTag(titleRaw)
  const background = backgroundData ? parseDataImage(backgroundData) : null
  const icon = iconData ? parseDataImage(iconData) : null

  return { id, items, subTitle, icon, title, background, pushTitle }
}

function parseFoodPoductCategoryItem(data) {
  const {
    id,
    bestOff: isPush,
    composition: recipe,
    nom: name,
    ombre: backgroundData,
    photo: packshotData,
  } = data

  const background = backgroundData ? parseDataImage(backgroundData) : null
  const packshot = backgroundData ? parseDataImage(packshotData) : null

  return { id, name, recipe, background, packshot, isPush }
}
