
export default {
  name: "LayoutNavigation",

  data() {
    return {
      opened: false,
      scrollPosition: 0
    };
  },

  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },

  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
  },

  methods: {
    onClick() {
      this.opened = !this.opened;
      this.scrollPosition = this.opened ? 0 : window.scrollY;
      this.$emit(this.opened ? "navigationOpen" : "navigationClose");
    },
    handleScroll() {
      this.scrollPosition = window.scrollY;
    }
  }

};
