import { parseDataSocials } from './dataSocials'

export function parseParameters(data) {
  const { socialNetworks: socialsData, formIntros: formData } = data.content

  const socials = parseDataSocials(socialsData)
  const form = { intro: formData }

  return { socials, form }
}
