import Vue from 'vue'

export const state = Vue.observable({
  storeId: '',
})

export const mutations = {
  preselectStoreById(id) {
    state.storeId = id !== null ? id.toString() : ''
  }
}
