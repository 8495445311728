export const IS_BRANCH_STAGING =
  process.env.BRANCH && process.env.BRANCH === 'staging'

export function createNavigationPopinItem(
  componentName,
  componentOptions,
  url
) {
  return { componentName, componentOptions, url }
}

export function modulo(value, n) {
  return ((value % n) + n) % n
}

const ENUM_FORMAT = {
  thumbnail: 0,
  small: 1,
  medium: 2,
  large: 3,
  origin: 4,
}
const MIN_FORMAT = ENUM_FORMAT.large // force minimum format

export function bestPicFormat(imageDataObject, formatList = []) {
  if (!imageDataObject) return null
  const available = formatList
    .filter((fName) => (ENUM_FORMAT[fName] || -1) >= MIN_FORMAT)
    .find(
      (f) =>
        imageDataObject[f] &&
        imageDataObject[f].url &&
        imageDataObject[f].url.length
    )
  return available ? imageDataObject[available].url : null
}

export function truncateText(text = '', charCount = 300, suffix = ' ...') {
  if (text.length <= charCount) return text
  const cutted = text.slice(0, charCount).trim()
  const lastSpace = cutted.lastIndexOf(' ')
  return cutted.slice(0, lastSpace) + suffix
}

export function randomizeArray(array) {
  return array.sort(() => 0.5 - Math.random())
}

/**
 * This function is same as PHP's nl2br() with default parameters.
 *
 * @param {string} str Input text
 * @param {boolean} replaceMode Use replace instead of insert
 * @param {boolean} isXhtml Use XHTML
 * @return {string} Filtered text
 * https://gist.github.com/yidas/41cc9272d3dff50f3c9560fb05e7255e
 */
export function nl2br (str, replaceMode, isXhtml) {
  const breakTag = (isXhtml) ? "<br />" : "<br>";
  const replaceStr = (replaceMode) ? "$1" + breakTag : "$1" + breakTag + "$2";
  return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, replaceStr);
}

export function replacePictosPlaceholder(text) {
  const notNull = (value) => value !== null
  const available = { beer: '/images/picto-beer.png' }
  const placeholders = text.match(/%([a-z,0-9]+)%/gim)

  if (!placeholders) return text

  let out = text
  placeholders
    .filter(notNull)
    .map((p) => p.replaceAll('%', ''))
    .forEach((p) => {
      if (available[p]) {
        out = out.replace(
          `%${p}%`,
          `<img class="picto-text" src="${available[p]}" />`
        )
      }
    })

  return out
}

export function removeEncapsulatingTag(str) {
  return (str && str.match(/^<[a-z]*>/i)) ? str.replace(/^<[a-z]*>/i, '').replace(/<\/[a-z]*>$/i, '') : str
}
