function parseLink(linkData) {
  let data = linkData
  data = data.attributes || data

  if (!data) return null

  const { title: label, url: route, target, children: childrenData = [] } = data
  const children = childrenData.data ? childrenData.data.map(parseLink) : []
  return { label, route, target, children }
}

function parseLinks(linksData = []) {
  let data = linksData
  data = data.content || data
  data = data.items || data
  data = data.data || data

  if (!data || !Array.isArray(data)) return null

  return data.map(d => parseLink(d))
}

export function parseDataNavigation(data) {
  return parseLinks(data)
}