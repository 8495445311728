import { parseDataPageDefault } from './dataPageDefault'
import { parseDataImage, parseDataGallery } from './dataMedia'
import { parseDataMetaTags, MetaObj } from './dataMetaTags'
import { parseDataSocials } from './dataSocials'

function formatHour(s) {
  return s.replace(':', 'h').replace('h00', 'h')
}

function parseOpenings(data) {
  const { day, closed } = data
  const from = formatHour(data.from)
  const to = formatHour(data.to)
  return { day, closed, from, to }
}

export function parseDataPageStores(data) {
  const commonData = parseDataPageDefault(data)
  const stores = data.stores.sort((a, b) => a.name.localeCompare(b.name))
  const elCounter = `<span class="count">${stores.length}</span>`

  let { Intro: intro = '' } = data.content
  intro = intro.replaceAll('%count%', elCounter)

  return { ...commonData, intro, content: { stores } }
}

export function parseDataStore(data) {
  const {
    id,
    slug,
    draft,
    coordonnees: coordinatesData = null,
    horaires: openingsData = [],
    nom: name,
    ville: city,
    codePostal: zipcode,
    adresse: address,
    telephone: phone,
    email: mail,
    description,
    galerie: galleryData = null,
    googleID,
    googleBusinessMapUrl,
    googleBusinessRatings,
    googleBusinessScore,
    socials: socialsData,
    offre: offersData = [],
    restauration: foodData = [],
    voisins: nearData = null
  } = data

  const coordinates = coordinatesData ? coordinatesData.split(',').reverse() : null
  const openings = openingsData && openingsData.length ? openingsData.map(parseOpenings) : []
  const offers = offersData && offersData.length ? offersData.map(parseOfferData) : []
  const gallery = galleryData ? parseDataGallery(galleryData) : null
  const near = nearData && nearData.data && nearData.data.length ? nearData.data.map(d => parseDataStore(d.attributes)) : null
  const page = `/etablissements/${slug}/`
  const pic = parseDataImage(data.photo)
  const socials = socialsData ? parseDataSocials(socialsData) : null

  const defaultMetaTags = new MetaObj(
    `${name}-${city}`,
    description?.replaceAll(/<(\/){0,1}p>/g, ''),
    data.photo,
    slug)
  const metaTags = parseDataMetaTags(data, defaultMetaTags, page)

  const hasFood = {
    onSite: foodData ? foodData.includes('0') : false,
    onTakeAway: foodData ? foodData.includes('1') : false,
    onDelivery: foodData ? foodData.includes('2') : false,
  }
  const googleData = {
    id: googleID,
    mapUrl: googleBusinessMapUrl,
    rateUrl: `https://search.google.com/local/writereview?placeid=${googleID}`,
    ratings: googleBusinessRatings,
    score: googleBusinessScore,
  }

  return {
    id,
    draft,
    metaTags,
    name,
    address,
    zipcode,
    city,
    phone,
    mail,
    coordinates,
    description,
    openings,
    offers,
    hasFood,
    gallery,
    googleData,
    near,
    page,
    pic,
    socials,
    slug,
  }
}

function parseOfferData(data) {
  const { id, nom: name, description, image: imageData } = data
  const image = imageData && imageData.data ? parseDataImage(imageData.data) : null
  return { id, name, description, image }
}
