import { render, staticRenderFns } from "./error.vue?vue&type=template&id=f2158272&scoped=true&"
import script from "./error.vue?vue&type=script&lang=js&"
export * from "./error.vue?vue&type=script&lang=js&"
import style0 from "./error.vue?vue&type=style&index=0&id=f2158272&prod&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f2158272",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {PageTitle: require('/opt/build/repo/components/PageTitle.vue').default,RichText: require('/opt/build/repo/components/RichComponents/RichText.vue').default,ButtonBlob: require('/opt/build/repo/components/ButtonComponents/ButtonBlob.vue').default})
