import { parseComponentList } from './dataCustomComponents'
import { parseDataHeaderMedia } from './dataHeaderMedia'
import { parseDataMetaTags } from './dataMetaTags'
import { parsePageTitle } from './dataPageTitle'

export function parseDataPageDefault(data) {
  // get page meta data
  const metaTags = parseDataMetaTags(data)

  // get page content
  const { content = null } = data || {}

  if (!content) {
    return { metaTags }
  }

  const {
    draft = false,
    contenus: componentsData = null,
    headerMedia: headerMediaData = null,
  } = content

  // get page components list
  const components = componentsData ? parseComponentList(componentsData) : null

  // get page header media (image or video)
  const headerMedia = headerMediaData ? parseDataHeaderMedia(headerMediaData) : null

  // get page rich title
  const title = parsePageTitle(content)

  return { components, draft, headerMedia, metaTags, title }
}
