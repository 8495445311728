import { parseComponentList } from './dataCustomComponents'
import { parseDataMetaTags } from './dataMetaTags'
import { parsePageTitle } from './dataPageTitle'

export function parseDataPageBasic(data) {
  const metaTags = parseDataMetaTags(data)
  const title = parsePageTitle(data.content)
  const components =
    data.content && data.content.contenus
      ? parseComponentList(data.content.contenus)
      : null
  const { draft } = data.content
  const pageData = { draft, metaTags, title, components }
  return pageData
}
