import axios from 'axios'

import { parseDataPageBasic } from './dataObjects/dataPageBasic'
import { parseDataNavigation } from './dataObjects/dataNavigation'
import {
  parseDrinksBeers,
  parseDrinksKegs,
  parseDrinksLiquors,
  parsePageDrinks,
} from './dataObjects/dataPageDrinks'
import { parseDataPageFood } from './dataObjects/dataPageFood'
import { parseDataPageTireuse } from './dataObjects/dataPageTireuse'
import { parseParameters } from './dataObjects/dataParameters'
import { parseEvents, parsePageEvents } from './dataObjects/dataPageEvents'
import { parseDataPageFranchise } from './dataObjects/dataPageFranchise'
import {
  parsePageHome,
  parsePeopleList,
  parseQuotes,
} from './dataObjects/dataPageHome'
import { parseDataPageMarque } from './dataObjects/dataPageMarque'
import { parseNews, parsePageNews } from './dataObjects/dataPageNews'
import {
  parseDataPageStores,
  parseDataStore,
} from './dataObjects/dataPageStores'
import { isDataDraft } from './dataObjects/dataUtils'
import { IS_BRANCH_STAGING } from './utils'

const API_ROOT = process.env.API_ROOT
const ROUTE_MAIL = process.env.ROUTE_MAIL
const API_DEFAULT_PARAMS = IS_BRANCH_STAGING
  ? '?version=staging'
  : '?publicationState=live'
const LIMIT_ITEMS = process.env.LIMIT_ITEMS
const cachedResponses = {}

export async function sendMail(params) {
  const body = JSON.stringify({ data: params })
  return await fetch(ROUTE_MAIL, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body,
  })
}

async function fetchData(url) {
  let response

  if (cachedResponses[url]) {
    response = cachedResponses[url]
  } else {
    try {
      response = await axios(url, { method: 'GET' })

      if (!response.status || response.status !== 200 || !response.data)
        throw new Error('Server Error')
    } catch (error) {
      /* eslint-disable-next-line */
      console.error(`Server Error - ${url} -`, error)
      return { error }
    }
  }

  cachedResponses[url] = response

  const { meta, data: parsed } = response.data
  let content = []

  if (Array.isArray(parsed)) {
    (LIMIT_ITEMS ? parsed.slice(0, LIMIT_ITEMS) : parsed).forEach((item) =>
      content.push({
        ...item.attributes,
        id: item.id,
        draft: isDataDraft(item.attributes),
      })
    )
  } else {
    content = {
      ...parsed.attributes,
      id: parsed.id,
      draft: isDataDraft(parsed.attributes),
    }
  }

  return { meta, content, error: null }
}

/* navigation */

export async function fetchNavigation() {

  const url = API_ROOT + '/menus/1' + API_DEFAULT_PARAMS + '&nested&populate=*'
  if (cachedResponses[url] ) {
    return cachedResponses[url]
  }

  const dataMenu = await fetchData(url)
  const { socials } = await fetchParameters()
  const links = parseDataNavigation(dataMenu)
  cachedResponses[url] = { links, socials }
  return { links, socials }

}

/* common */

export async function fetchParameters() {

  const url = API_ROOT + '/parameter' + API_DEFAULT_PARAMS;

  if (cachedResponses[url] ) {
    return cachedResponses[url]
  }

  const raw = await fetchData(url)
  cachedResponses[url] = raw
  return parseParameters(raw)
}

export async function fetchFounders() {
  const raw = await fetchData(
    API_ROOT +
      '/portraits' +
      API_DEFAULT_PARAMS +
      '&filters[$and][0][type][$eq]=MegaBoss'
  )
  return parsePeopleList(raw)
}

/* home page */

export async function fetchHome() {
  const raw = await fetchData(API_ROOT + '/page-accueil' + API_DEFAULT_PARAMS)
  const peopleList = await fetchPeopleList()
  const quotes = await fetchQuotes()
  return parsePageHome({ ...raw, peopleList, quotes })
}

export async function fetchPeopleList() {
  const raw = await fetchData(API_ROOT + '/portraits' + API_DEFAULT_PARAMS)
  return parsePeopleList(raw)
}

export async function fetchQuotes() {
  const raw = await fetchData(API_ROOT + '/citations' + API_DEFAULT_PARAMS)
  return parseQuotes(raw)
}

/* la marque */

export async function fetchMarque() {
  const raw = await fetchData(API_ROOT + '/page-marque' + API_DEFAULT_PARAMS)
  const founders = await fetchFounders()
  return parseDataPageMarque({ ...raw, founders })
}

/* établissements */

export async function fetchStores() {
  const storesData = await fetchData(API_ROOT + '/etablissements' + API_DEFAULT_PARAMS)
  const stores = storesData.content.map(parseDataStore)
  return stores.sort((a, b) => a.name.localeCompare(b.name))
}

export async function fetchStoreBySlug(slug) {
  const stores = await fetchStores()
  return stores.find(store => store.slug === slug)
}

export async function fetchStoresPage() {
  const raw = await fetchData(API_ROOT + '/page-etablissement' + API_DEFAULT_PARAMS)
  const stores = await fetchStores()
  return parseDataPageStores({ ...raw, stores })
}

/* la franchise */

export async function fetchFranchise() {
  const raw = await fetchData(API_ROOT + '/page-franchise' + API_DEFAULT_PARAMS)
  return parseDataPageFranchise(raw)
}

/* evènements */

export async function fetchEvents() {
  const events = await fetchData(API_ROOT + '/evenements' + API_DEFAULT_PARAMS)
  return parseEvents(events)
}

export async function fetchEventsPage() {
  const raw = await fetchData(API_ROOT + '/page-evenement' + API_DEFAULT_PARAMS)
  const events = await fetchEvents()
  return parsePageEvents({ ...raw, events })
}

/* boissons */

export async function fetchDrinksPage() {
  const raw = await fetchData(API_ROOT + '/page-boisson' + API_DEFAULT_PARAMS)
  return parsePageDrinks(raw)
}

export async function fetchDrinksBeers() {
  const beers = await fetchData(
    API_ROOT + '/produits-bieres' + API_DEFAULT_PARAMS
  )
  return parseDrinksBeers(beers)
}

export async function fetchDrinksKegs() {
  const kegs = await fetchData(
    API_ROOT + '/produits-perfectdrafts' + API_DEFAULT_PARAMS
  )
  return parseDrinksKegs(kegs)
}

export async function fetchDrinksLiquors() {
  const liquors = await fetchData(
    API_ROOT + '/produits-spiritueuxes' + API_DEFAULT_PARAMS
  )
  return parseDrinksLiquors(liquors)
}

/* food */

export async function fetchFood() {
  const raw = await fetchData(API_ROOT + '/page-food' + API_DEFAULT_PARAMS)
  return parseDataPageFood(raw)
}

/* tireuse */

export async function fetchTireuse() {
  const raw = await fetchData(API_ROOT + '/page-tireuse' + API_DEFAULT_PARAMS)
  return parseDataPageTireuse(raw)
}

/* actualités */

export async function fetchNewsPage() {
  const raw = await fetchData(API_ROOT + '/page-actualite' + API_DEFAULT_PARAMS)
  const news = await fetchNews()
  return ({ ...parsePageNews(raw), news })
}

export async function fetchNews() {
  const raw = await fetchData(
    API_ROOT + '/actualites' + API_DEFAULT_PARAMS + '&sort=date%3Adesc'
  )
  return parseNews(raw)
}

/* mentions légales */

export async function fetchLegals() {
  const raw = await fetchData(API_ROOT + '/page-legals' + API_DEFAULT_PARAMS)
  return parseDataPageBasic(raw)
}

/* contact */

export async function fetchContact() {
  const raw = await fetchData(API_ROOT + '/page-contact' + API_DEFAULT_PARAMS)
  return parseDataPageBasic(raw)
}
