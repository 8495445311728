import { parseComponentList } from './dataCustomComponents'
import { parseDataHeaderMedia } from './dataHeaderMedia'
import { parseDataMetaTags } from './dataMetaTags'
import { parsePageTitle } from './dataPageTitle'

export function parseDataPageMarque(data) {
  const { draft } = data.content
  const metaTags = parseDataMetaTags(data)
  const title = parsePageTitle(data.content)
  const headerMedia = parseDataHeaderMedia(data.content.headerMedia)
  const components = parseComponentList(data.content.contenus)
  const founders = data.founders
  const pageData = { draft, metaTags, title, headerMedia, components, founders }
  return pageData
}
