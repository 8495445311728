import Vue from 'vue'

export const store = Vue.observable({
  popinOpened: false,
  popinContentComponent: null,
  popinNavigationItems: [],
  popinData: null,
  popinRouteListening: false,
  lightboxImgs: [],
  lightboxIndex: 0,
  screenLarge: false,
  touchDevice: false,
  isMobile: false,
  draft: false,
  drinks: {},
})

export const mutations = {
  openPopin() {
    store.popinOpened = true
  },

  closePopin() {
    store.popinOpened = false
    store.popinContentComponent = null
    store.popinNavigationItems = null
    store.popinData = null
  },

  getPopinComponent() {
    return store.popinContentComponent
  },

  setPopinComponent(component) {
    store.popinContentComponent = component
  },

  getPopinNavigationItems() {
    return store.popinNavigationItems
  },

  setPopinNavigationItems(items) {
    store.popinNavigationItems = items
  },

  getPopinData() {
    return store.popinData
  },

  setPopinData(data) {
    store.popinData = data
  },

  isPopinRouteListening() {
    return store.popinRouteListening
  },

  setPopinRouteListening(value) {
    store.popinRouteListening = value
  },

  openLightBox(imgs, index) {
    store.lightboxImgs.push(...imgs)
    store.lightboxIndex = Math.min(Math.max(index, 0), imgs.length - 1)
  },

  closeLightBox() {
    store.lightboxImgs.splice(0)
  },

  getScreenLarge() {
    return store.screenLarge
  },

  setScreenLarge(value) {
    store.screenLarge = value
  },

  setMobile(value) {
    store.isMobile = value
  },

  isMobile() {
    return store.isMobile
  },

  isTouchDevice() {
    return store.touchDevice
  },

  setTouchDevice(value) {
    store.touchDevice = value
  },

  saveDrinks(category, list) {
    store.drinks.category = category
    store.drinks.list = list
  },

  isDraft() {
    return store.draft
  },

  setDraft(value) {
    store.draft = value
  },

  getDrinks() {
    return store.drinks
  },
}
