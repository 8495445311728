import { parseComponentList } from './dataCustomComponents'
import { parseDataHeaderMedia } from './dataHeaderMedia'
import { parseDataMetaTags } from './dataMetaTags'
import { parsePageTitle } from './dataPageTitle'

export function parseDataPageFranchise(data) {
  const { draft } = data.content
  const metaTags = parseDataMetaTags(data)
  const title = parsePageTitle(data.content)
  const headerMedia =
    data.content && data.content.headerMedia
      ? parseDataHeaderMedia(data.content.headerMedia)
      : null
  const components =
    data.content && data.content.contenus
      ? parseComponentList(data.content.contenus)
      : null
  const pageData = { draft, metaTags, title, headerMedia, components }
  return pageData
}
