import { render, staticRenderFns } from "./TheLogo.vue?vue&type=template&id=bed7a516&scoped=true&v-once=true&"
import script from "./TheLogo.vue?vue&type=script&lang=js&"
export * from "./TheLogo.vue?vue&type=script&lang=js&"
import style0 from "./TheLogo.vue?vue&type=style&index=0&id=bed7a516&prod&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bed7a516",
  null
  
)

export default component.exports