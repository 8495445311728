import { removeEncapsulatingTag } from '../utils'

function parseFormat(data) {
  if (!data) return null
  const { width, height } = data
  const url = process.env.MEDIAS_ROOT + data.url
  return { url, width, height }
}

export function parseDataImage(data) {
  const defaultImage = { alt: null, origin: null }
  const formatList = ['small', 'medium', 'large', 'thumbnail']

  let root = data
  let alt
  let origin
  let out
  let cover = false
  let loopCount = 0

  if (!root) return defaultImage

  do {
    cover = cover || (root.taille && root.taille === 'cover')
    alt = alt || root.titre || root.alternativeText
    root =
      root.image ||
      root.visuel ||
      root.attributes ||
      root.data ||
      root[0] ||
      root
    if (root.url) {
      origin = parseFormat(root)
      out = { alt, origin, cover }
      formatList.forEach((f) => {
        out[f] = parseFormat(root.formats ? root.formats[f] : null)
      })
    }
    ++loopCount
  } while (
    loopCount < 10 &&
    root &&
    (root.image ||
      root.visuel ||
      root.attributes ||
      root.data ||
      Array.isArray(root))
  )

  return out || defaultImage
}

export function parseDataVideo(data) {
  if (!data)
    return {
      desktop: null,
      mobile: null,
      poster: null,
      title: null,
      text: null,
    }
  const {
    video_desktop: videoDesktop,
    video_mobile: videoMobile,
    titre: title,
    description: text,
    player_options: playerOptions,
    youtube_video_id: youtubeID,
  } = data
  const poster = parseDataImage(data.vignette)
  const videoDesktopURL = videoDesktop?.data?.attributes?.url
  const videoMobileURL = videoMobile?.data?.attributes?.url
  const options = { controls: true }

  if (playerOptions) {
    playerOptions.split(' ').forEach((key) => {
      options[key] = true
    })
  }

  return {
    desktop: videoDesktopURL ? process.env.MEDIAS_ROOT + videoDesktopURL : null,
    mobile: videoMobileURL ? process.env.MEDIAS_ROOT + videoMobileURL : null,
    youtubeID,
    poster,
    title,
    text,
    options,
  }
}

export function parseDataGallery(data) {
  const {
    id,
    header: headerData,
    imagesList: imagesData,
  } = data

  const richtext = headerData && headerData.richtext ? removeEncapsulatingTag(headerData.richtext) : null
  const images = imagesData && imagesData.data && Array.isArray(imagesData.data) ? imagesData.data.map(parseDataImage) : []

  return { id, richtext, images }
}
