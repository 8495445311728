import { nl2br, removeEncapsulatingTag } from '../utils'

export function parsePageTitle(data = {}) {
  if (data.richTitle && data.richTitle.richTitle) {
    return removeEncapsulatingTag(data.richTitle.richTitle)
  }

  const {
    titreLigne1 = null,
    titreLigne2 = null,
    titreLigne3 = null
  } = data.titre || {};
  return [titreLigne1, nl2br(titreLigne2, true, true), nl2br(titreLigne3, true, true)];
}
