
import { fetchStores } from '@/assets/scripts/api'

export default {
  props: {
    value: { type: String, default: '' },
    name: { type: String, default: null },
  },

  data() {
    return {
      stores: [],
    }
  },

  async fetch() {
    const storeDataList = await fetchStores()
    this.stores.push(...storeDataList)
  },

  computed: {
    options() {
      return this.stores.map(s => ({
        // label: `${s.name} / ${s.city} - ${s.zipcode}`,
        label: `${s.name} - ${s.zipcode}`,
        value: s.id.toString(),
        ...s,
      }))
    }
  }
}
