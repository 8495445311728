import Cookies from "js-cookie";

const loadScript = (src, callback) => {
  // Vérifie si le script est déjà dans le document
  if (!document.querySelector(`script[src="${src}"]`)) {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.src = src;
    script.onload = () => {
      console.log(`Script loaded successfully: ${src}`);
      callback(true);
    };
    document.head.appendChild(script);
  } else {
    console.log(`Script already loaded: ${src}`);
    callback(false);
  }
};


const initGoogleTagManager = (gtmId) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    'gtm.start': new Date().getTime(),
    event: 'gtm.js'
  });
  loadScript(`https://www.googletagmanager.com/gtm.js?id=${gtmId}`, (isNewlyLoaded) => {
    if (isNewlyLoaded) {
      console.log("GTM initialized after loading.");
    } else {
      console.log("GTM initialization skipped, script already loaded.");
    }
  });
};

const initGoogleAds = (googleAdsId) => {
  window.dataLayer = window.dataLayer || [];
  loadScript(`https://www.googletagmanager.com/gtag/js?id=${googleAdsId}`, (isNewlyLoaded) => {
    if (isNewlyLoaded) {
      window.gtag = function() { dataLayer.push(arguments); };
      gtag('js', new Date());
      gtag('config', googleAdsId);
      console.log("Google Ads script initialized after loading.");
    } else {
      console.log("Google Ads script initialization skipped, already loaded.");
    }
  });
};


const initAxeptio = (dataId, cookiesVersion) => {
  const el = document.createElement('script');
  el.src = 'https://static.axept.io/sdk-slim.js';
  el.type = 'text/javascript';
  el.async = true;
  el.setAttribute('data-id', dataId);
  el.setAttribute('data-cookies-version', cookiesVersion);
  document.head.appendChild(el);

  // Save user preferences
  void 0 === window._axcb && (window._axcb = [])
  window._axcb.push(function (axeptio) {
    axeptio.on('cookies:complete', function (choices) {

    // Set mybeers cookie if user accepts
      if (choices.mybeers) {

        const allowedKeys = ['mtm_campaign','mtm_source','mtm_medium','gad_source','mtm_keyword','mtm_content','mtm_cid','mtm_group','mtm_placement','utm_campaign','utm_source','utm_medium','utm_term','utm_content','utm_id'];
        const queryParams = new URLSearchParams(window.location.search);
        const paramsObject = {};

        queryParams.forEach((value, key) => {
          if (allowedKeys.includes(key)) {
            paramsObject[key] = value;
          }
        });
        if (Object.keys(paramsObject).length) {
          Cookies.set('mybeers', JSON.stringify(paramsObject), {
            expires: 30,
            secure: true,
            sameSite: 'strict'
          });
        }
     }

      if (choices.googletagmanager) {
        initGoogleTagManager('GTM-MHFPRNLT');
      }
      if (choices.google_ads) {
        initGoogleAds('AW-10933821033');
      }

      // Matomo code
      let axeptio_Matomo = []
      for (let vendor in choices) {
        if (vendor != '$$completed' && choices[vendor] == true) {
          _mtm.push({ event: `axeptio_activate_${vendor}` })
          axeptio_Matomo.push(vendor)
        }
      }
      _mtm.push({ axeptio_Matomo: axeptio_Matomo })
    })
  })
}

if (typeof document === 'object' && !process.env.AXEPTIO_DISABLED) {
  initAxeptio('61fe4d0c0089c7a0010d4403', 'mybeers-fr')
}
