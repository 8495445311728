
export default {
  props: {
    imgs: { type: Array, default: () => [] },
    start: { type: Number, default: 0 }
  },

  data() {
    return {
      index: 0,
    }
  },

  watch: {
    imgs(value) {
      this.index = Math.max(Math.min(this.start, value.length - 1), 0)
    }
  },
}
