import { removeEncapsulatingTag, replacePictosPlaceholder } from "../utils";
import { parseDataImage, parseDataVideo } from "./dataMedia";

export function parseComponentTitle(data) {
  return { cname: 'title', text: data.titre }
}

export function parseComponentColumn(data) {
  const {
    couleurFond: colorBackground,
    colorText,
    largeur,
    textBoxAlignement: textBoxAlign,
  } = data
  const image = parseDataImage(data.image || data.visuel)
  const text = parseComponentRichText(data.texte).text
  const border = largeur === 'avecMarge'
  return {
    cname: 'column',
    image,
    text,
    colorBackground,
    colorText,
    border,
    textBoxAlign,
  }
}

export function parseComponentImage(data) {
  const image = parseDataImage(data.image || data.visuel)
  return { cname: 'image', image }
}

export function parseComponentVideo(data) {
  const video = parseDataVideo(data)
  return { cname: 'video', video }
}

export function parseComponentEnumeration(data) {
  const { type } = data

  if (type === 'etapes' || type === 'formation') {
    let title
    let headText
    let text
    let pic
    const items = data.items.map((i) => {
      ;({ numPrefixe: title, headTexte: headText } = i)
      text = replacePictosPlaceholder(i.contenu)
      pic = i.headIcono && i.headIcono.data ? parseDataImage(i.headIcono) : null
      return { title, headText, text, pic }
    })
    return { cname: 'enumeration', items, type }
  }

  if (type === 'block') {
    let text
    let pic
    const title = data.items && data.items[0] ? data.items[0].contenu : null
    const items = data.items.slice(1).map((i) => {
      ;({ contenu: text } = i)
      pic = i.headIcono && i.headIcono.data ? parseDataImage(i.headIcono) : null
      return { text, pic }
    })
    return { cname: 'enumeration', title, items, type }
  }

  if (type === 'forces') {
    let text
    let pic
    const items = data.items.map((i) => {
      ;({ contenu: text } = i)
      pic = i.headIcono && i.headIcono.data ? parseDataImage(i.headIcono) : null
      return { text, pic }
    })
    return { cname: 'enumeration', items, type }
  }

  if (type === 'ca-map') {
    const dataHeadItem = data.items.find((i) => i.numPrefixe === 'head')
    const dataItems = data.items.filter((i) => i.numPrefixe !== 'head')
    const headItem = {
      text: dataHeadItem.contenu,
    }
    let text
    let title
    let id
    const items = dataItems.map((i) => {
      ;({ numPrefixe: id, headTexte: title, contenu: text } = i)
      return { id, title, text }
    })
    return { cname: 'enumeration', headItem, items, type }
  }

  return { cname: 'enumeration', ...data }
}

export function parseComponentRichTitle(data) {
  const text =
    data && data.richTitle
      ? removeEncapsulatingTag(data.richTitle)
      : null
  return { cname: 'richtitle', text }
}

// remove staging hard coded domain and add a trailing slash on relative and absolute local urls if needed
export function parseComponentRichText(data) {
  const text =
    data && data.richtext
      ? data.richtext.replace(
        /(http|https):\/\/staging--mybeers.netlify.app/g,
        'https://www.mybeers.fr'
      )
      : null;

  const updatedText =
    text &&
    text.replace(
      /<a\b[^>]*href=["'](https?:\/\/www\.mybeers\.fr[^"']*)["'][^>]*>(.*?)<\/a>/gi,
      (match, url, content) => {
        if (!url.endsWith('/')) {
          url += '/';
        }
        return `<a href="${url}">${content}</a>`;
      }
    );

  const sanitizedText =
    updatedText &&
    updatedText.replace(
      /<a\b[^>]*href=["'](https?:\/\/www\.mybeers\.fr[^"']*)["'][^>]*>/gi,
      (match) => {
        return match.replace(/(target|rel)=["'][^"']*["']/gi, '');
      }
    );

  return { cname: 'richtext', text: sanitizedText };
}

export function parseComponentCalculator(data) {
  const seasons = {
    spring: { label: 'printemps', ratio: 1 / data.printemps },
    summer: { label: 'été', ratio: 1 / data.ete },
    autumn: { label: 'automne', ratio: 1 / data.automne },
    winter: { label: 'hiver', ratio: 1 / data.hiver },
  }
  const { explicatif: mentions } = data
  return { cname: 'calculator', seasons, mentions }
}

export function parseComponentList(data) {
  const list = []

  function addToList(el) {
    list.push(el)
  }

  data.forEach((c) => {
    switch (c.__component) {
      case 'contents.titre':
        addToList(parseComponentTitle(c))
        break
      case 'contents.colonne':
        addToList(parseComponentColumn(c))
        break
      case 'contents.image':
        addToList(parseComponentImage(c))
        break
      case 'contents.video':
        addToList(parseComponentVideo(c))
        break
      case 'contents.enumeration':
        addToList(parseComponentEnumeration(c))
        break
      case 'contents.texte-riche':
        addToList(parseComponentRichText(c))
        break
      case 'contents.titre-riche':
        addToList(parseComponentRichTitle(c))
        break
      case 'contents.calculator':
        addToList(parseComponentCalculator(c))
        break
      default:
        addToList({ cname: c.__component, raw: c })
    }
  })
  return list
}
