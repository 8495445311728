import { parseDataImage } from './dataMedia'
import { parseDataMetaTags, MetaObj } from './dataMetaTags'
import { parsePageTitle } from './dataPageTitle'

function parseHeader(data) {
  let pic, intro, title, maskIdentity
  return data.map((header) => {
    ;({ titre: title, intro, maskIdentity } = header)
    pic = parseDataImage(header.image)
    return { pic, intro, title, maskIdentity }
  })
}

export function parsePageDrinks(data) {
  const metaTags = parseDataMetaTags(data)
  const title = parsePageTitle(data.content)
  const { draft, intro, headerBoisson: rawHeaders } = data.content
  const headers = parseHeader(rawHeaders)
  return { draft, metaTags, title, headers, intro }
}

export function parseBeer(data) {
  const { id, draft } = data
  const {
    nom: name,
    pays: country,
    brasserie: brewery,
    couleur: color,
    volume: size,
    categorie: category,
    style,
    description,
    slug,
  } = data.attributes || data
  const properties = {
    fruitée: data.bool_fruitee,
    epicée: data.bool_epicee,
    equilibrée: data.bool_equilibree,
    acide: data.bool_acide,
    maltée: data.bool_maltee,
    torréfiée: data.bool_torrefiee,
    parfumée: data.bool_parfumee,
    douce: data.bool_douce,
    amère: data.bool_amere,
    houblonnée: data.bool_houblonnee,
    ronde: data.bool_rond,
  }
  const pic = data.Photo ? parseDataImage(data.Photo) : null
  const degrees = `${parseFloat(data.degres)}°C`

  const defaultMetaTags = new MetaObj(name, description?.replaceAll(/<(\/){0,1}p>/g, ''), data.Photo, slug)
  const metaTags = parseDataMetaTags(data, defaultMetaTags,"/boissons/" + slug + "/")

  return {
    id,
    draft,
    metaTags,
    slug,
    pic,
    name,
    country,
    brewery,
    color,
    degrees,
    size,
    category,
    style,
    description,
    properties,
  }
}

export function parseDrinksBeers(data) {
  return data.content.map(parseBeer)
}

export function parseDrinksKegs(data) {
  return data.content.map((k) => {
    const {
      id = null,
      slug,
      draft,
      nom: name,
      pays: country,
      couleur: color,
      description,
    } = k
    const properties = {
      acide: k.bool_acid,
      amère: k.bool_amere,
      douce: k.bool_douce,
      épicée: k.bool_epicee,
      équilibrée: k.bool_equilibree,
      fruitée: k.bool_fruitee,
      houblonnée: k.bool_houblonne,
      malte: k.bool_malte,
      parfumée: k.bool_parfume,
      sucrée: k.bool_sucree,
      torréfiée: k.bool_torrefiee,
    }
    const pic = parseDataImage(k.photo)
    const degrees = `${parseFloat(k.degres)}°C`

    const defaultMetaTags = new MetaObj(name, description?.replaceAll(/<(\/){0,1}p>/g, ''), k.photo, slug)
    const metaTags = parseDataMetaTags(k, defaultMetaTags,"/boissons/" + slug + "/")

    return {
      id,
      draft,
      metaTags,
      slug,
      pic,
      name,
      country,
      color,
      degrees,
      description,
      properties,
    }
  })
}

export function parseDrinksLiquors(data) {
  return data.content.map((drink) => {
    const { id, slug, draft, nom: name, description, type, style } = drink
    const properties = {
      boisé: drink.level_boise,
      epicé: drink.level_epice,
      floral: drink.level_floral,
      fruité: drink.level_fruite,
      iodé: drink.level_iode,
      tourbé: drink.level_tourbe,
    }
    const pic = parseDataImage(drink.photo)
    const degrees = `${parseFloat(drink.degres)}°C`

    const defaultMetaTags = new MetaObj(name, description?.replaceAll(/<(\/){0,1}p>/g, ''), drink.photo, slug)
    const metaTags = parseDataMetaTags(drink, defaultMetaTags, "/boissons/" + slug + "/")

    return {
      id,
      draft,
      metaTags,
      slug,
      pic,
      name,
      type,
      style,
      degrees,
      description,
      properties,
    }
  })
}

export function getDrinkProperties(drink) {
  return (Object.keys(drink.properties || {}) || []).filter(
    (k) => drink.properties[k] === true || drink.properties[k] > 0
  )
}

export function getDrinkIdentity(drink, mask) {
  const propertiesString = getDrinkProperties(drink)
    .map((p) => `<strong>${p}</strong>`)
    .join(', ')
    .replace(/,([^,]*)$/, ' et $1')
  const matches = {
    '%pays%': `<strong>${drink.country}</strong>`,
    '%couleur%': `<strong>${drink.color}</strong>`,
    '%degres%': `<strong>${drink.degrees}</strong>`,
    '%type%': `<strong>${drink.type}</strong>`,
    '%style%': `<strong>${drink.style}</strong>`,
    '%bools%': propertiesString,
    '%levels%': propertiesString,
  }

  let identity = mask || ''
  Object.keys(matches).forEach((k) => {
    identity = identity.replace(k, matches[k])
  })
  return identity
}
