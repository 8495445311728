/* eslint-disable */
function initTracking() {
  if (typeof window === 'object') {
    var _paq = (window._paq = window._paq || [])
    /* tracker methods like "setCustomDimension" should be called before "trackPageView" */
    _paq.push(['setDoNotTrack', true])
    _paq.push(['disableCookies'])
    _paq.push(['trackPageView'])
    _paq.push(['enableLinkTracking'])
    ;(function () {
      var u = 'https://mybeers.matomo.cloud/'
      _paq.push(['setTrackerUrl', u + 'matomo.php'])
      _paq.push(['setSiteId', '1'])
      var d = document,
        g = d.createElement('script'),
        s = d.getElementsByTagName('script')[0]
      g.async = true
      g.src = '//cdn.matomo.cloud/mybeers.matomo.cloud/matomo.js'
      s.parentNode.insertBefore(g, s)
    })()
  }
}

if (typeof document === 'object') {
  document.readyState === 'complete' || document.readyState === 'interactive'
    ? setTimeout(initTracking, 1)
    : document.addEventListener('DOMContentLoaded', initTracking)
}
