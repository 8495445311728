import { parseDataImage } from './dataMedia'
import { parseDataMetaTags, MetaObj } from './dataMetaTags'
import { parsePageTitle } from './dataPageTitle'

export function parsePageEvents(data) {
  const metaTags = parseDataMetaTags(data)
  const title = parsePageTitle(data.content)
  const { events } = data
  const { draft, titreIntro: introTitle, texteIntro: introText } = data.content
  return { draft, metaTags, title, introTitle, introText, events }
}

export function parseEvents(data) {
  let id
  let draft
  let defaultMetaTags
  let metaTags
  let title
  let when
  let description
  let metaDescription
  let picWide
  let picSquare
  let slug

  return data.content.map((event) => {
    ;({
      id = null,
      slug,
      draft,
      titre: title = '',
      quand: when = '',
      description = '',
    } = event)

    const descriptionWithoutTags = description?.replace(/<[^>]*>/g, "");
    metaDescription = descriptionWithoutTags && (descriptionWithoutTags.length + 3) > 160
      ? descriptionWithoutTags?.substring(0, 157) + "..."
      : descriptionWithoutTags?.substring(0, 160);

    picWide = parseDataImage(event.imageWide)
    picSquare = parseDataImage(event.imageSquare)
    defaultMetaTags = new MetaObj(
      title,
      metaDescription,
      event.imageWide,
      slug
    );
    metaTags = parseDataMetaTags(event, defaultMetaTags,"/evenements/" + slug + "/")
    return {
      id,
      draft,
      metaTags,
      title,
      when,
      description,
      picWide,
      picSquare,
      slug,
    }
  })
}
