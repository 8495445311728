import sanitizeHtml from 'sanitize-html'

import { truncateText } from '../utils'
import { parseDataPageDefault } from './dataPageDefault'
import { parseComponentList } from './dataCustomComponents'
import { parseDataImage } from './dataMedia'
import { parseDataMetaTags, MetaObj } from './dataMetaTags'

export function dateFormat(date) {
  if (!date) return 'date non définie'
  const [y, m, d] = date.split('-')
  const raw = new Date(y, m - 1, d)
  return raw.toLocaleDateString('fr-FR', {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
  })
}

export function parsePageNews(data) {
  const commonData = parseDataPageDefault(data)
  return commonData
}

export function parseNews(data) {
  return data.content.map((news) => {
    const { id, slug, draft, titre: title, categories, date, publishedAt } = news
    const image = parseDataImage(news.image)
    const components = parseComponentList(news.contenus)
    const shortTitle = truncateText(title, 60)
    const label = truncateText(title, 20)
    const firstParagraph = components.find((c) => c.cname === 'richText')
    const shortDescription = firstParagraph
      ? truncateText(
          sanitizeHtml(firstParagraph.text, {
            allowedTags: [],
            allowedAttributes: {},
          }),
          100
        )
      : ''
    const defaultMetaTags = new MetaObj(shortTitle, shortDescription, news.image, slug)
    const metaTags = parseDataMetaTags(news, defaultMetaTags,"/actualites/" + slug + "/")

    return {
      id,
      draft,
      metaTags,
      title,
      shortTitle,
      categories,
      label,
      shortDescription,
      date: dateFormat(date),
      rawDate: date,
      publishedAt,
      image,
      components,
      slug,
    }
  })
}

export function findNewsBySlug(news, slug) {
  return news && news.length ? news.find((n) => n.slug === slug) : null
}
