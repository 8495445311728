
import smoothscroll from 'smoothscroll-polyfill'
import Cookies from 'js-cookie';

import { mutations, store } from '@/assets/scripts/store'
import { IS_BRANCH_STAGING } from '@/assets/scripts/utils'

// eslint-disable-next-line
typeof window !== 'undefined' ? smoothscroll.polyfill() : null

export default {
  name: 'Default',

  data() {
    return {
      navigationOpened: false,
    }
  },

  computed: {
    isMobile() {
      return store.isMobile
    },
    isPopinOpen() {
      return store.popinOpened
    },
    lightboxImgs() {
      return store.lightboxImgs
    },
    lightboxIndex() {
      return store.lightboxIndex
    },
    popinData: mutations.getPopinData,
    popinComponent: mutations.getPopinComponent,
    popinNavigationItems: mutations.getPopinNavigationItems,
    isDraft: mutations.isDraft,
    shouldDisplayForm() {
      return this.$route.name !== 'mentions-legales'
    },
  },

  watch: {
    $route(to) {
      if (to.hash.indexOf('#goto:') === 0) {
        this.$router.push({ hash: null })
        setTimeout(() => {
          this.requestForm()
        }, 1)
      }
    },

    navigationOpened(value) {
      if (typeof document === 'undefined') return

      if (value) {
        document.body.classList.add('navigation-opened')
      } else {
        document.body.classList.remove('navigation-opened')
      }
    }
  },

  /* eslint-disable */
  created() {
    this.$router.afterEach(() => {
      this.updatePopinStateFromURL()
      if (window._paq) {
        window._paq.push(['setCustomUrl', window.location.href]);
        window._paq.push(['trackPageView']);
      }
    })
    this.updatePopinStateFromURL()
    this.draftMentionEnabled = IS_BRANCH_STAGING
  },
  /* eslint-enable */

  mounted() {

    this.handleCampaignCookie();
    window.addEventListener('beforeunload', this.handleBeforeUnload);

    if (window) {
      this.resizeHandler = () => {
        mutations.setScreenLarge(window.innerWidth >= 1024)
        mutations.setTouchDevice(
          'ontouchstart' in window ||
          navigator.maxTouchPoints > 0 ||
          navigator.msMaxTouchPoints > 0
        )
        mutations.setMobile(/Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))
      }

      this.handleBeforeUnload = (event) => {
        this.handleCampaignCookie();
      }

      this.resizeHandler()
      window.addEventListener('resize', this.resizeHandler)
    }

    this.$router.beforeEach((to, from, next) => {
      this.handleCampaignCookie(to);
      next();
    });

  },

  beforeDestroy() {
    if (window) {
      window.removeEventListener('resize', this.resizeHandler);
      window.removeEventListener('beforeunload', this.handleBeforeUnload);
    }
  },

  methods: {

    updatePopinStateFromURL() {
      this.$route.params.popin ? mutations.openPopin() : mutations.closePopin()
    },

    requestPopinClose() {
      if (this.$route.params.popin) {
        if (this.$route.name === 'portrait-popin') {
          this.$router.push({ name: 'index', params: { popin: null }})
          return
        }

        this.$router.push({ params: { popin: null } })
        return
      }
      mutations.closePopin()
    },

    requestLightBoxClose() {
      mutations.closeLightBox()
    },

    requestForm() {
      if (
        this.$route.name !== 'contact' &&
        this.$route.name !== 'mentions-legales'
      ) {
        setTimeout(() => {
          this.$refs.form.scrollIntoView({ behavior: 'smooth' })
        }, 100)
      } else {
        this.$router.push('/#goto:deviens-franchise')
      }
    },

    handleCampaignCookie(to = this.$route) {
      const isProduction = process.env.NODE_ENV === 'production';
      const allowedKeys = ['mtm_campaign','mtm_source','mtm_medium','gad_source','mtm_keyword','mtm_content','mtm_cid','mtm_group','mtm_placement','utm_campaign','utm_source','utm_medium','utm_term','utm_content','utm_id'];
      const queryParams = new URLSearchParams(window.location.search);
      const paramsObject = {};

      queryParams.forEach((value, key) => {
        if (allowedKeys.includes(key)) {
          paramsObject[key] = value;
        }
      });

      const authorizedVendors = Cookies.get('axeptio_authorized_vendors');

      // Check if 'mybeers' is among the authorized sellers
      if (authorizedVendors && authorizedVendors.includes('mybeers')) {
        // If there are relevant parameters and 'mybeers' is allowed, we store the parameters in a cookie
        if (Object.keys(paramsObject).length) {
          Cookies.set('mybeers', JSON.stringify(paramsObject), {
            expires: 30,
            secure: isProduction,
            sameSite: 'strict'
          });
        }
      } else {
        // If 'mybeers' is no longer authorized, delete the cookie
        Cookies.remove('mybeers', { secure: isProduction, sameSite: 'strict' });
      }
    }

  },

}
