
export default {
  name: 'PageTitle',

  props: {
    title: { type: [Array, String], default: null },
  },

  computed: {
    richTitle() {
      return (typeof this.title === 'string') ? this.title : null
    },

    standardTitle() {
      return (Array.isArray(this.title)) ? this.title : null
    }
  }
}
