
export default {
  props: {
    lightTheme: { type: Boolean, default: false },
  },

  data() {
    return {
      loading: true,
    }
  },

  mounted() {
    setTimeout(() => {
      this.loading = false
    }, 250)
  },
}
