
export default {
  model: {
    prop: 'checked',
    event: 'change',
  },

  props: {
    checked: { type: Boolean, default: false },
  }
}
