import { parseDataImage } from './dataMedia'

function parseIcon(iconData) {
  const data = parseDataImage(iconData)
  return data.origin
}

function parseSocial(socialData) {
  const { nom: name, url: href, icon } = socialData
  return { name, href, icon: parseIcon(icon) }
}

export function parseDataSocials(socialsData = []) {
  let data = socialsData
  data = data.content || data
  data = data.socialNetworks || data

  if (!data || !Array.isArray(data)) return null

  return data.map(d => parseSocial(d))
}
