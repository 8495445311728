
export default {
  props: {
    inputProps: { type: Object, default: () => {} },
    label: { type: String, default: null },
    name: { type: String, default: null },
    options: { type: Array, default: () => [] },
    value: { type: String, default: '' },
  },

  data() {
    return {
      keyboardIndex: -1,
      isOpen: false,
    }
  },

  computed: {
    selected() {
      const option = this.options.find(({ value }) => value === this.value)
      if (!option) return null

      const { label, value } = option
      return { label, value }
    }
  },

  methods: {
    select({ value }) {
      this.$emit('input', value)
    },

    open() {
      this.isOpen = true
      this.keyboardIndex = -1
      document.addEventListener('keydown', this.keyboardHandler)
    },

    close() {
      this.isOpen = false
      document.removeEventListener('keydown', this.keyboardHandler)
    },

    keyboardHandler(e) {
      e.preventDefault()
      switch (e.key) {
        case 'ArrowDown':
          this.keyboardIndex = (this.keyboardIndex + 1) % this.options.length
          break
        case 'ArrowUp':
          this.keyboardIndex = (this.keyboardIndex <= 0) ? this.options.length - 1 : this.keyboardIndex - 1
          break
        case ' ':
        case 'Enter':
        case 'Tab':
          this.$refs.input.blur()
          if (this.keyboardIndex < 0) return
          this.select(this.options[this.keyboardIndex])
      }
    }
  }
};
