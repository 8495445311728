import { parseDataHeaderMedia } from './dataHeaderMedia'
import { parseDataVideo } from './dataMedia'
import { parseDataMetaTags } from './dataMetaTags'
import { parseBeer } from './dataPageDrinks'
import { parseDataStore } from './dataPageStores'
import { parsePageTitle } from './dataPageTitle'

export function parsePageHome(data) {
  const { peopleList, quotes } = data
  const metaTags = parseDataMetaTags(data)
  const title = parsePageTitle(data.content)
  const more = {
    text: data.content.enSavoirPlus,
    href: data.content.enSavoirPlusLien,
  }
  const {
    draft,
    texteIntro: textIntro,
    headerMedia: headerMediaData,
  } = data.content
  const headerMedia = parseDataHeaderMedia(headerMediaData)
  return { draft, metaTags, title, textIntro, headerMedia, more, peopleList, quotes }
}

export function parsePeopleList(data) {
  let id,
    draft,
    metaTags,
    slug,
    name,
    status,
    presentation,
    job,
    video,
    beer,
    store
  const list = data.content
  const peopleList = list
    .map((p) => {
      ;({ id, slug, draft, prenom: name, type: status, presentation, Poste: job } = p)
      metaTags = parseDataMetaTags(p)
      video = parseDataVideo(p.video)
      beer = p.biere && p.biere.data ? parseBeer(p.biere.data) : {}
      store =
        p.etablissement &&
        p.etablissement.data &&
        p.etablissement.data.attributes
          ? parseDataStore(p.etablissement.data.attributes)
          : {}
      return {
        id,
        draft,
        metaTags,
        slug,
        name,
        status,
        presentation,
        job,
        video,
        beer,
        store,
      }
    })
    .sort((a, b) => a.slug.localeCompare(b.slug))
  return peopleList
}

export function parseQuotes(data) {
  return data && data.content
    ? data.content.map((q) => ({ author: q.auteur, text: q.citation }))
    : []
}
