/* eslint-disable */

// LIVE RELEASE INSTALLED
// for dev you may use container_w38zab8J_dev_f104100dbe36d74303bbef98.js
// for staging : container_w38zab8J_staging_1108e529f4949c3a28a5e351.js

if (typeof window === 'object') {
  const _mtm = (window._mtm = window._mtm || []);
  _mtm.push({ "mtm.startTime": new Date().getTime(), event: "mtm.Start" });
  const d = document,
    g = d.createElement("script"),
    s = d.getElementsByTagName("script")[0];
  g.async = true;
  g.src = "https://cdn.matomo.cloud/mybeers.matomo.cloud/container_w38zab8J.js";
  s.parentNode.insertBefore(g, s);
}
