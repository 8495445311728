
import { fetchNavigation } from '@/assets/scripts/api'

export default {
  name: 'TheNavigation',

  data() {
    return {
      navData: {},
      year: new Date().getFullYear(),
    }
  },

  async fetch() {
    const navData = await fetchNavigation()
    this.navData = navData
  },
}
