export function INPUT_NOT_EMPTY(value) {
  return value && value !== '' && value.trim() !== ''
}

export function INPUT_VALID_MAIL(value) {
  return value.match(/^[\w-.]+@([\w-]+.)+[\w-]{2,4}$/)
}

export function INPUT_VALID_PHONE(value) {
  return (
    value.match(
      /(?:(?:\+|00)33[\s.-]{0,3}(?:\(0\)[\s.-]{0,3})?|0)[1-9](?:(?:[\s.-]?\d{2}){4}|\d{2}(?:[\s.-]?\d{3}){2})$/
    ) && value.replace(/^\+33/, '0').replaceAll(' ', '').length === 10
  )
}

export function checkInput(value, validators = []) {
  return validators.every((v) => v(value))
}
