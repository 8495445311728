
import { mutations, store } from '@/assets/scripts/store'

function removeDetailParamFromPath(path, detail) {
  const reg = new RegExp('/' + detail + '/*$')
  return path.replace(reg, '')
}

export default {
  name: 'DetailLayout',

  computed: {
    lightboxImgs() {
      return store.lightboxImgs
    },

    lightboxIndex() {
      return store.lightboxIndex
    },
  },

  methods: {
    closePageHandler() {
      const path = removeDetailParamFromPath(this.$route.path, this.$route.params.detail)
      this.$router.push({ path })
    },

    closeLightBoxHandler() {
      mutations.closeLightBox()
    },
  }
}
