import { parseDataImage, parseDataVideo } from './dataMedia'

export function parseDataHeaderMedia(data) {
  const { coverText } = data
  const image = parseDataImage(data.image)
  const overlayImage = parseDataImage(data.coverImage)
  const video = parseDataVideo(data.video)
  const videoPreview = parseDataVideo(data.videoPreview)
  overlayImage.alt = coverText
  return { overlayImage, image, video, videoPreview }
}
