import { render, staticRenderFns } from "./default.vue?vue&type=template&id=fb516534&scoped=true&"
import script from "./default.vue?vue&type=script&lang=js&"
export * from "./default.vue?vue&type=script&lang=js&"
import style0 from "./default.vue?vue&type=style&index=0&id=fb516534&prod&lang=stylus&"
import style1 from "./default.vue?vue&type=style&index=1&id=fb516534&prod&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fb516534",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {TheNavigation: require('/opt/build/repo/components/TheNavigation.vue').default,TheForm: require('/opt/build/repo/components/TheForm.vue').default,ButtonBlob: require('/opt/build/repo/components/ButtonComponents/ButtonBlob.vue').default,PopinNavigation: require('/opt/build/repo/components/PopinNavigation.vue').default,ThePopin: require('/opt/build/repo/components/ThePopin.vue').default,ButtonClose: require('/opt/build/repo/components/ButtonComponents/ButtonClose.vue').default,ButtonArrow: require('/opt/build/repo/components/ButtonComponents/ButtonArrow.vue').default,GalleryLightBox: require('/opt/build/repo/components/GalleryComponents/GalleryLightBox.vue').default})
